@import 'src/mixins';

.product-category {
  .owl-item img {
    margin: auto;
    width: 75px !important;
  }

  .owl-dots {
    bottom: -13px !important;
  }
}

.parallax {
  //min-height: 492px;
  min-height: auto;
  height: 40vh;
  position: relative;
  overflow: hidden;

  .bg {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    //opacity: 0.5;
    /* The image used */

    /* Set a specific height */

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg2 {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(21, 25, 27, 0.65);
    /* The image used */

    /* Set a specific height */

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .parallax-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate3d(-50%, -50%, 0);
    text-align: center;
    width: 70%;

    .title {
      font-weight: 600;
      font-size: 48px;
      line-height: 52px;
      pointer-events: all;
      padding-top: 8px;
      padding-bottom: 8px;
      text-transform: none;
      letter-spacing: .03em;
      max-height: calc(calc(52px * 2) + 2px);
      overflow: hidden;

      @include respond-below(xl) {
        font-size: 48px;
        line-height: 52px;
        max-height: calc(calc(52px * 2) + 2px);
        overflow: hidden;
      }
      @include respond-below(lg) {
        font-size: 48px;
        line-height: 52px;
        max-height: calc(calc(52px * 2) + 2px);
        overflow: hidden;
      }
      @include respond-below(md) {
        font-size: 38px;
        line-height: 42px;
        max-height: calc(calc(42px * 2) + 2px);
        overflow: hidden;
      }
      @include respond-below(sm) {
        font-size: 24px;
        line-height: 28px;
        max-height: calc(calc(28px * 2) + 2px);
        overflow: hidden;
      }
      @include respond-below(xs) {
        font-size: 24px;
        line-height: 28px;
        max-height: calc(calc(28px * 2) + 2px);
        overflow: hidden;
      }
    }

    .description {
      font-weight: 300;
      font-size: 24px;
      line-height: 28px;
      margin-top: 16px;
      pointer-events: all;
      max-height: calc(calc(28px * 4) + 2px);
      overflow: hidden;

      @include respond-below(xl) {
        font-size: 24px;
        line-height: 28px;
        max-height: calc(calc(28px * 4) + 2px);
        overflow: hidden;
      }
      @include respond-below(lg) {
        font-size: 24px;
        line-height: 28px;
        max-height: calc(calc(28px * 4) + 2px);
        overflow: hidden;
      }
      @include respond-below(md) {
        font-size: 14px;
        line-height: 18px;
        max-height: calc(calc(18px * 4) + 2px);
        overflow: hidden;
      }
      @include respond-below(sm) {
        font-size: 14px;
        line-height: 18px;
        max-height: calc(calc(18px * 4) + 2px);
        overflow: hidden;
      }
      @include respond-below(xs) {
        font-size: 14px;
        line-height: 18px;
        max-height: calc(calc(18px * 4) + 2px);
        overflow: hidden;
      }
    }
  }

}


.collection-template {
  padding-top: 3rem;
  margin-bottom: 6rem;
  position: relative;

  &.hidden-effect {
    opacity: 0;
    visibility: hidden;
    background: #ececec;
  }

  opacity: 1;
  visibility: unset;
  background: unset;
  transition: visibility 0s, opacity 0.7s, background 3s linear;

  @include respond-below(xl) {
    margin-bottom: 6rem;
  }
  @include respond-below(lg) {
    margin-bottom: 5rem;
  }
  @include respond-below(md) {
    margin-bottom: 5rem;
  }
  @include respond-below(sm) {
    margin-bottom: 4rem;
  }
  @include respond-below(xs) {
    margin-bottom: 4rem;
  }

  &:hover {
    .edit-section {
      opacity: 1;
    }
  }

  .grid {
    display: grid;
    padding: .3rem;

    &.c1 {
      grid-template-columns: auto;
    }

    &.c3 {
      grid-template-columns: auto auto auto;
    }

    &.c4 {
      grid-template-columns: auto auto auto auto;
    }

    &.c5 {
      grid-template-columns: auto auto auto auto auto;
    }

    &.c6 {
      grid-template-columns: auto auto auto auto auto auto;
    }


    .grid-item {
      margin: 0.5rem;
      font-size: 30px;
      text-align: center;
      align-content: center;
      justify-content: center;
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 0;
        padding-top: calc(100% / (4 / 3));
        position: relative;
      }

      &.span::before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 0;
        padding-top: unset;
        position: relative;
      }

      .content {
        background-color: rgba(255, 255, 255, 0.8);
        //border: 1px solid rgba(0, 0, 0, 0.8);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-items: center;
        align-items: center;

        .content-hover {
          top: 0;
          left: 0;
          position: absolute;
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s, opacity 0.5s linear;
          z-index: 3;
          background-color: rgba(0, 0, 0, 0.3);
        }

        &:hover {
          .content-hover {
            opacity: 1;
            visibility: unset;
            transition: visibility 0s, opacity 0.3s linear;
          }
        }

      }


      .content-inside {
        width: 100%;
        flex: 1;
        align-items: center;
        justify-items: center;
        display: flex;
        flex-flow: column;

        .image-container {
          position: absolute;
          overflow: hidden;
          background-repeat: no-repeat;
        }

        .data {
          z-index: 1;
        }

        .title {
          z-index: 1;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.8);
        }
      }

      .content-overlay {
        position: absolute;
        left: 7%;
        top: 0;
        height: 100%;
        width: 35%;
        background-color: rgba(65, 43, 114, 0.5);
        display: flex;
        z-index: 4;
      }

      &.sbn::before {
        padding-top: calc(100% / (16 / 9));
      }

      &.tbf::before {
        padding-top: calc(100% / (3 / 4));
      }

      &.fbt::before {
        padding-top: calc(100% / (4 / 2));
      }

      &.fbo::before {
        padding-top: calc(100% / (4 / 1));
      }


      @include respond-below(sm) {

        &.sbn::before, &.tbf::before, &.fbt::before, &.fbo::before {
          padding-top: calc(100% / (4 / 3));
        }

      }

    }

    .rs2 {
      grid-row: span 2;
    }

    .rs3 {
      grid-row: span 3;
    }

    .rs4 {
      grid-row: span 4;
    }

    .cs2 {
      grid-column: span 2;
    }

    .cs3 {
      grid-column: span 3;
    }

    .cs4 {
      grid-column: span 4;
    }

    .cs6 {
      grid-column: span 6;
    }

    @include respond-below(sm) {

      .rs2, .rs3, .rs4 {
        grid-row: span 1;
      }

      .cs2, .cs3, .cs4, .cs6 {
        grid-column: span 1;
      }

      &.c3, &.c4, &.c5, &.c6 {
        grid-template-columns: auto auto;
      }

    }

  }

  .template-heading, .template-heading .editable {
    display: inline-block;
    font-size: 22px;
    color: #333c4e;
    line-height: 28px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    position: relative;
    text-align: center;
    text-rendering: auto;
    max-height: calc(calc(28px * 2) + 2px);
    //overflow: hidden;

    @include respond-below(xl) {
      font-size: 22px;
      line-height: 28px;
      max-height: calc(calc(28px * 2) + 2px);
      //overflow: hidden;
    }
    @include respond-below(lg) {
      font-size: 22px;
      line-height: 28px;
      max-height: calc(calc(28px * 2) + 2px);
      //overflow: hidden;
    }
    @include respond-below(md) {
      font-size: 20px;
      line-height: 26px;
      max-height: calc(calc(26px * 2) + 2px);
      //overflow: hidden;
    }
    @include respond-below(sm) {
      font-size: 20px;
      line-height: 26px;
      max-height: calc(calc(26px * 2) + 2px);
      //overflow: hidden;
    }
    @include respond-below(xs) {
      font-size: 20px;
      line-height: 26px;
      max-height: calc(calc(26px * 2) + 2px);
      //overflow: hidden;
    }

  }

  .template-description {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    color: #6e7077;
    font-weight: 300;
    -webkit-font-smoothing: auto;
    position: relative;
    text-align: center;
    max-height: calc(calc(24px * 4) + 2px);
    //overflow: hidden;

    @include respond-below(xl) {
      font-size: 16px;
      line-height: 24px;
      max-height: calc(calc(24px * 4) + 2px);
      //overflow: hidden;
    }
    @include respond-below(lg) {
      font-size: 16px;
      line-height: 24px;
      max-height: calc(calc(24px * 4) + 2px);
      //overflow: hidden;
    }
    @include respond-below(md) {
      font-size: 14px;
      line-height: 22px;
      max-height: calc(calc(22px * 4) + 2px);
      //overflow: hidden;
    }
    @include respond-below(sm) {
      font-size: 14px;
      line-height: 22px;
      max-height: calc(calc(22px * 4) + 2px);
      //overflow: hidden;
    }
    @include respond-below(xs) {
      font-size: 14px;
      line-height: 22px;
      max-height: calc(calc(22px * 4) + 2px);
      //overflow: hidden;
    }
  }

  .template-quote {
    font-size: 26px;
    line-height: 38px;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    color: #8894ab;
    font-weight: 300;
    position: relative;
    text-align: center;
    max-height: calc(calc(38px * 4) + 2px);
    //overflow: hidden;

    @include respond-below(xl) {
      font-size: 26px;
      line-height: 38px;
      max-height: calc(calc(38px * 4) + 2px);
      //overflow: hidden;
    }
    @include respond-below(lg) {
      font-size: 26px;
      line-height: 38px;
      max-height: calc(calc(38px * 4) + 2px);
      //overflow: hidden;
    }
    @include respond-below(md) {
      font-size: 24px;
      line-height: 36px;
      max-height: calc(calc(36px * 4) + 2px);
      //overflow: hidden;
    }
    @include respond-below(sm) {
      font-size: 16px;
      line-height: 28px;
      max-height: calc(calc(28px * 4) + 2px);
      //overflow: hidden;
    }
    @include respond-below(xs) {
      font-size: 16px;
      line-height: 28px;
      max-height: calc(calc(28px * 4) + 2px);
      //overflow: hidden;
    }

  }

  .template-header {
    padding: 0 7rem;
    margin-bottom: 58px;

    @include respond-below(xl) {
      padding: 0 5rem;
      margin-bottom: 58px;
    }
    @include respond-below(lg) {
      padding: 0 5rem;
      margin-bottom: 58px;
    }
    @include respond-below(md) {
      padding: 0 3rem;
      margin-bottom: 48px;
    }
    @include respond-below(sm) {
      padding: 0 2rem;
      margin-bottom: 25px;
    }
    @include respond-below(xs) {
      padding: 0 1rem;
      margin-bottom: 25px;
    }

  }

  .template-footer {
    padding: 0 7rem;

    @include respond-below(xl) {
      padding: 0 5rem;
    }
    @include respond-below(lg) {
      padding: 0 5rem;
    }
    @include respond-below(md) {
      padding: 0 3rem;
    }
    @include respond-below(sm) {
      padding: 0 2rem;
    }
    @include respond-below(xs) {
      padding: 0 1rem;
    }

  }

  .editable {
    width: fit-content;
  }

  .edit-section {
    opacity: 0;
    position: absolute;
    right: 15px;
    top: 5px;
    z-index: 10;
    transition: all 0.2s;
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid rgba(225, 70, 148, 0.2);
  border-top-color: #FFF;
  border-radius: 50%;
  animation: spinner-border 1s ease-in-out infinite;
  -webkit-animation: spinner-border 1s ease-in-out infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

.mb-3rem {
  margin-bottom: 3rem;
}

.separator {
  border-bottom: 1px solid #d7d7d7;
}

.cursor-pointer {
  cursor: pointer !important;
}

.toolbox {

  .select-custom {
    min-width: 150px;
    max-width: 150px;

    .form-control {
      min-width: 150px !important;
      max-width: 150px !important;
    }
  }
}

.intro-slide {
  .webgl-view {
    height: 60vh;
  }
}

.error-message {
  color: #dc3545 !important
}

.product-zoom-gallery {
  .owl-prev {
    left: 0 !important;
  }

  .owl-next {
    right: 0 !important;
  }
}

.all-h-inherit {
  div {
    height: inherit !important;
  }
}

.Sirv.h-auto {
  height: 100% !important;

  .smv.smv-selectors-bottom {
    height: 100% !important;
  }
}

.quotation-table.table-mobile td {
  display: table-cell !important;
}

@media screen and (min-width: 768px) {
  .toolbox .toolbox-right + .toolbox-right {
    margin-left: 1rem;
  }
}

.product-details .details-filter-row label {
  width: 95px;
}

.product-nav.product-nav-thumbs.product-thumb a {
  width: 8rem;
  height: 6rem;
}

@media screen and (max-width: 767px) {
  .product-part:not(:first-of-type) {
    //border-top: 1px solid #b5b5b5;
  }
}

@media screen and (min-width: 768px) {
  .product-part:nth-of-type(even) {
    //border-left: 1px solid #b5b5b5;
  }
}

.text-initials {
  position: absolute;
  left: 50%;
  right: auto;
  top: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
  font-size: 3rem;
}

/* Add to Cart Modal*/

.modal-content {
  &.cart-dropdown {
    button.mfp-close {
      font: normal normal 2.6rem/1 'molla';
      position: absolute;
      color: #666;
      top: 0.9rem;
      right: 0.9rem;
      display: block;
    }
  }
}

.ReactModal__Overlay.add-to-cart-overlay {
  z-index: 3000 !important;
  position: fixed;
  inset: 0px;
  background-color: rgba(51, 51, 51, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

//.btn-product-gallery {
//  position: absolute;
//  right: 2rem;
//  bottom: 2rem;
//  z-index: 49;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  text-align: center;
//  width: 4rem;
//  height: 4rem;
//  color: #777;
//  font-weight: normal;
//  font-size: 2rem;
//  line-height: 1;
//  background-color: #fff;
//  box-shadow: 2px 6px 16px rgba(51, 51, 51, .05);
//  transition: all .35s ease;
//
//  &:hover,
//  &:focus {
//    color: #fff;
//    background-color: #777;
//  }
//}

.tp-caption .btn--vertical {
  width: auto;
  height: 58px;
  writing-mode: unset;
  line-height: 58px !important;
  padding: 0 30px !important;
}

.category-slider {

  .product-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    img {
      width: 45px;
      height: 45px;
    }

    &:focus {
      color: #000000;
    }

    &:hover {
      color: #000000;
    }

    .product-title .text {
      position: relative;
      overflow: hidden;
      padding-bottom: 4px;
    }

  }

  .active, a:hover {

    img {
      width: 47px;
      height: 47px;
    }

    .product-title .text {
      font-weight: 600;

      &::before {
        content: " ";
        border-top: 2px solid #000;
        position: absolute;
        bottom: 0px;
        height: 1px;
        width: 100%;
        -webkit-animation: slide-from-left 0.5s forwards;
        animation: slide-from-left 0.5s forwards;
      }

      @-webkit-keyframes slide-from-left {
        0% {
          left: -100%;
        }
        100% {
          left: 0;
        }
      }

    }
  }
}

.footer-company-name {
  white-space: nowrap;
  margin: 0;
}

.category--img {
  position: relative;
  width: 100%;
  padding-top: 66.66%;

  .image-container {
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain
    }
  }
}

.cart-product-item {
  .cart-product-img-1 img {
    max-width: 100px;
    //max-height: 129px;
    width: 100px;
    height: 75px;
    object-fit: contain;

  }
}

input[type=email] {
  text-transform: lowercase !important;
}

.quotation-product-item {
  .quotation-product-img img {
    max-width: 60px;
    max-height: 60px;
  }
}

#rev_slider_15_1 {

  .tp-caption {
    color: #000 !important;
  }

  .tparrows:before {
    color: #000 !important;
  }

  .btn.btn--vertical {

    color: #000 !important;
    border-color: #000 !important;

    &:hover {
      color: #fff !important;
      border-color: #fff !important;
    }

  }

}

.header-white {
  .module-menu-container .menu-button, .icon-bag {
    color: #000 !important;
    font-size: 18px;
  }

  .module-menu-container .menu-button, .ti-heart {
    color: #000 !important;
    font-size: 18px;
  }

  .menu-popup .menu--popup-links li a:hover {
    color: #979797;
  }
}

.cart-box .cart-overview {
  li {
    min-height: 0;
    padding-bottom: 0;
  }

  img {
    height: 52.2px;
    object-fit: contain;
  }
}

.logo-light {
  height: 100px;
  margin: auto;
  display: block;
  max-width: 270px;
  object-fit: contain;

  &.home {
    margin-top: 15px;
  }

  @include respond-below(xl) {
  }
  @include respond-below(lg) {
  }
  @include respond-below(md) {
  }
  @include respond-below(sm) {
    height: 80px;
  }
  @include respond-below(xs) {
  }

}

.overflow-hidden {
  overflow: hidden;
}

.quotation-side-bar {
  list-style: none;

  li > * {
    color: #A1A1A1;
    cursor: pointer;
  }

  li.active > * {
    color: #000000;
  }
}

.enquiry-form .form-control {

  background-color: #f6f7fb;
  border: none;
  font-size: 15px;
  color: #7d7d7d;
  font-weight: 400;
  padding: 0 21px;
  height: 45px;
  margin-bottom: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-transform: capitalize;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 40px;
  background-color: #000000;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  z-index: 2;
}

.my-float {
  margin-top: 18px;
}

.fa-facebook, .fa-facebook-square {
  color: #3b5998;
}

.fa-twitter, .fa-twitter-square {
  color: #00aced;
}

.fa-google-plus, .fa-google-plus-square {
  color: #dd4b39;
}

.fa-youtube, .fa-youtube-play, .fa-youtube-square {
  color: #bb0000;
}

.fa-tumblr, .fa-tumblr-square {
  color: #32506d;
}

.fa-vine {
  color: #00bf8f;
}

.fa-flickr {
  color: #ff0084;
}

.fa-vimeo-square {
  color: #aad450;
}

.fa-pinterest, .fa-pinterest-square {
  color: #cb2027;
}

.fa-linkedin, .fa-linkedin-square {
  color: #007bb6;
}

.fa-instagram {
  color: #517fa4;
}

.fa-spotify {
  color: #1ed760;
}

.fa-whatsapp {
  color: #fff;
  background: linear-gradient(#25d366, #25d366) 14% 84%/16% 16% no-repeat,
  radial-gradient(#25d366 60%, transparent 0);
}

.module-cart-3 .cart-box .cart-overview li, .cart-box .cart-overview li {
  min-height: 100px;
}

.module-cart .module-box {
  max-height: 400px;
  overflow: auto;
}

.cart-product-content.cart-product {
  max-width: calc(100% - 100px);
  width: 100%;
  overflow: hidden;
}

.anuraj-export-factory {
  background-color: #6b6b6b;
  color: #005f97;

  &:hover {
    color: #005f97;
    background-color: #ffffff;
  }
}

.enquiry-form-label {
  font-size: 15px;
  font-weight: 400;
  color: rgb(66, 69, 97);
}